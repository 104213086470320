<template>
  <div>
    <commonTitle v-bind:datas="datas"></commonTitle>
	<!-- 监控系统 -->
	<div class="clearfix">
		<div ref=""
		     class="core clearfix pab60 qiandao animated slow hide show fadeIn show fadeIn">
		  <header class="mgt60">
		    <p class="font_24 center font_b core-title animated"
		       data-animation="fadeInUp"
		       animation-status="true">
		      储能监控系统
		    </p>
			<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
			<br />
			<p class="font_16 center mgt20 cdol-desc">
			 产品软硬件自主研发，高效稳定
			</p>
			<br />
		  </header>
		  <div style="margin: auto; height: 30vw; width: 60vw;">
		    <Carousel
		      :autoplay="true"
		      :duration="2000"
		      :initIndex="0"
		      :direction="true"
		      directionMode="hover"
		      :directionSize="20"
		      directionColor="skyblue"
		      :indicator="true"
		      indicatorMode="always"
		      indicatorColor="white"
		      indicatorActiveColor="skyblue"
		      
		      >
		      <CarouselItem v-for="(item, index) in data" :key="index" :idx="index">
		          <img :src="item" />
		      </CarouselItem>
		  	</Carousel>
		  </div>
		</div>
	</div>
	
	<!-- 控制器 -->
    <div class="clearfix">
		<div ref=""
		     class="core clearfix pab60 qiandao animated slow hide show fadeIn show fadeIn">
		  <header class="mgt60">
		    <p class="font_24 center font_b core-title animated"
		       data-animation="fadeInUp"
		       animation-status="true">
		      储能控制器
		    </p>
			<p style="width:50px;height:3px;margin:10px auto;padding:0px;background-color:#268A42;overflow:hidden;"></p>
			<br />
			<p class="font_16 center mgt20 cdol-desc">
			  电池管理系统（BMS）、能量管理系统（EMS)、现场控制系统（SiteController）
			</p>
			<br />
		  </header>
		  <div class="container mgt40">
		    <div class="clearfix core-content">
		      <div class="cor-box animated slow hide show fadeInRight">
		        <!-- <div class="ab-left">
		          <div class="box-img">
		            <img src="@/assets/images/sysbusiness/icon_zhibo.png"
		                 alt="" />
		          </div>
		        </div> -->
		        <div class="ab-right">
		          <div class="box-title">
		            <p>SiteController</p>
		          </div>
				  <div >
				      <img src="@/assets/images/sysindex/SiteController.png"
				           alt="" />
				  </div>
		          <div class="box-desc">
					<span class="dot">●&nbsp;&nbsp;</span>
					<span class="content-title">主要功能 </span>
					<p style="text-align:left">
						<span >储能电站监控/能源调度/故障告警</span>
					</p>
					<br />
					<span class="dot">●&nbsp;&nbsp;</span>
					<span class="content-title">产品特性 </span>
					<p style="text-align:left">
						<span>毫秒级功率实时监控和调度、智能化的自动告警故障保护策略</span>  
					</p>
					<!-- <span class="content-title">规格参数: </span>
					<span>&nbsp;&nbsp;查看详情<br/></span> -->
					<div>
						<!-- <button @click="checkProductMore('SiteController')" class="selfbutton selfbutton-pos-20">了解更多</button> -->
					</div>
					<!-- <span>时间： 2017年</span> -->
		          </div>
		        </div>
				
			  </div>
		      <div class="cor-box animated slow hide show fadeInLeft" >
		        <!-- <div class="ab-left">
		          <div class="box-img">
		            <img src="@/assets/images/sysbusiness/icon_zhibo.png"
		                 alt="" />
		          </div>
		        </div> -->
		        <div class="ab-right">
		          <div class="box-title">
		            <p>sEMS</p>
		          </div>
		          <div style="margin: auto;height: 303px;">
		              <img style="width: 70%; margin-top: 85px;margin-left:20% ;" src="@/assets/images/sysindex/sEMS.png"
		                   alt="" />
		          </div>
		          <div class="box-desc">
					  <span class="dot">●&nbsp;&nbsp;</span>
					  <span class="content-title">主要功能 </span>
					  <p style="text-align:left">
					  	<span >电池堆状态监控/充放电管理/SOC和SOH估算/系统故障诊断/告警和保护</span>
					  </p>
					  <br />
					  <span class="dot">●&nbsp;&nbsp;</span>
					  <span class="content-title">产品特性 </span>
					  <p style="text-align:left">
					  	<span>毫秒级指令控制、精准电池监控、精准匹配电池特性的充放电控制</span>  
					  </p>
		            <!-- <span class="content-title">规格参数:</span>
					<span>&nbsp;&nbsp;查看详情<br/></span> -->
		            <div>
		            	<!-- <button @click="checkProductMore('SEMS')" class="selfbutton selfbutton-pos-20">了解更多</button> -->
		            </div>
		          </div>
		        </div>
		      </div>
			  <div class="cor-box animated slow hide show fadeInRight">
			    <div class="ab-right">
			      <div class="box-title">
			        <p>BCU</p>
			      </div>
					  <div style="margin: auto;">
						  <img style="height: 234px;" src="@/assets/images/sysindex/BCU.png"
							   alt="" />
					  </div>
			      <div class="box-desc"> 
					<span class="dot">●&nbsp;&nbsp;</span>
					<span class="content-title">主要功能 </span>
					<p style="text-align:left">
						<span >电池组状态监控/电压、电流检测/高压上下电管理/SOC和SOH估算/限制电流计算/系统故障诊断、告警和保护</span>
					</p>
					<br />
					<span class="dot">●&nbsp;&nbsp;</span>
					<span class="content-title">产品特性 </span>
					<p style="text-align:left">
						<span>毫秒级指令控制、精准的SOC模型计算</span>
					</p>
			        <!-- <span class="content-title">规格参数: </span>
					<span>&nbsp;&nbsp;查看详情<br/></span> -->
			        <div>
			        	<!-- <button @click="checkProductMore('BMU')" class="selfbutton selfbutton-pos-20">了解更多</button> -->
			        </div>
			      </div>
			    </div>
			  			
			  </div>
			  <div class="cor-box animated slow hide show fadeInLeft" >
			    <!-- <div class="ab-left">
			      <div class="box-img">
			        <img src="@/assets/images/sysbusiness/icon_zhibo.png"
			             alt="" />
			      </div>
			    </div> -->
			    <div class="ab-right">
			      <div class="box-title">
			        <p>BMU</p>
			      </div>
			      <div style="margin: auto;">
			          <img style="height: 265px;margin-left: 27%;" src="@/assets/images/sysindex/BMU.png"
			               alt="" />
			      </div>
			      <div class="box-desc">
					  <span class="dot">●&nbsp;&nbsp;</span>
					  <span class="content-title">主要功能 </span>
					  <p style="text-align:left">
						<span >电芯电压/温度采集/高效主动均衡(1-5A)</span>
					  </p>
					  <br />
					  <span class="dot">●&nbsp;&nbsp;</span>
					  <span class="content-title">产品特性 </span>
					  <p style="text-align:left">
						<span>独特的主动均衡模式，无与伦比的安全性</span>
					  </p>
					<!-- <span class="content-title">规格参数:</span>
					<span>&nbsp;&nbsp;支持CAN/RS485等<br/></span> -->
					<div>
						<!-- <button @click="checkProductMore('BCU')" class="selfbutton selfbutton-pos-20">了解更多</button> -->
					</div>
			      </div>
			    </div>	
			  </div>
			  <!-- <div class="cor-box animated slow hide show fadeInRight">
				<div class="ab-right">
				  <div class="box-title">
					<p>cEMS</p>
				  </div>
					  <div >
						  <img src="@/assets/images/sysindex/cEMS.png"
							   alt="" />
					  </div>
				  <div class="box-desc">
					<span>主要功能: 集装箱级系统监控/功率分配/系统故障诊断/告警和保护<br/></span>
					<span>产品特性: 500kW/1MWh<br/></span>
					<span>规格参数: 光储结合<br/></span>
					<div>
						<button @click="checkProductMore('SiteController')" class="selfbutton selfbutton-pos-20">了解更多</button>
					</div>
				  </div>
				</div>
			  </div>	 -->		  			
		    </div>
		  </div>
		</div>
    </div>
	
	
	
    
  </div>
</template>
<script>
import commonTitle from '@/components/common/commonTitle.vue'
export default {
  name: 'Product',
	components: {
		commonTitle,
	},
  data() {
    return {
	  datas:["product0"],
	  data: [
			// "https://ns-strategy.cdn.bcebos.com/ns-strategy/upload/fc_big_pic/part-00693-2745.jpg",
			require("../assets/images/banner/jk-4.png"),
			require("../assets/images/banner/jk-1.png"),
			require("../assets/images/banner/jk-2.png"),
			require("../assets/images/banner/jk-3.png"),
	  ],
    }
  },
  methods: {
	// 滑动
	toLocal() {
	  //查找存储的锚点id
	  let Id = localStorage.getItem("cec");
	  let toElement = document.getElementById(Id);
	  //锚点存在跳转
	  if (Id) {
		toElement.scrollIntoView();
	  }
	},
	checkProductMore(productName) {
		this.$router.push('/Product/' + productName)
	},
	// checkProductDetail(stype,isbool) {
	// 	switch(stype){
	// 		case "indoor":
	// 			this.indoorShow = isbool;
	// 			// window.open(require('@/assets/resolution/'));
	// 			break;
	// 		case "outdoor":
	// 			this.outdoorShow = isbool;
	// 			break;
	// 		case "grid":
	// 			this.gridShow = isbool;
	// 			break;
	// 		case "standAloneBat":
	// 			this.standAloneBatShow = isbool;
	// 			break;
	// 	}
	// },
 //    handleScroll() {
 //      //获取滚动时的高度
 //      let scrollTop =
 //        window.pageYOffset ||
 //        document.documentElement.scrollTop ||
 //        document.body.scrollTop
 //      let oneHeight = this.$refs.scrollOne.offsetHeight
 //      let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight
 //      let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight
 //      let fourHeight = this.$refs.scrollFour.offsetHeight + threeHeight;
 //      if (scrollTop > oneHeight) {
 //        this.showOne = true
 //      }
 //      if (scrollTop > twoHeight) {
 //        this.showTwo = true
 //      }
 //      if (scrollTop > threeHeight) {
 //        this.showThree = true
 //      }
	// if (scrollTop > fourHeight) {
	//   this.showFour = true;
	// }
 //    },
  },
  created() {
  	this.$nextTick(() =>{
		this.toLocal();
	})
  },
  
  mounted() {
	window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
	localStorage.setItem("cec","")
  },
}
</script>
<style lang="scss">
@import '@/assets/css/business.scss';

.b_border {
    background: #2f73f8;
    height: 3px;
    width: 40px;
    margin-top: 14px
}
.content-title {
	font-weight: 700;
	font-size:100% ;
	color: #044c26;
}
.dot {
  font-size: 16px;
  color: #044c26;
  line-height: 2;
}


.selfbutton {  
        width: 160px;  
        padding:8px;   
		border: 1px solid transparent;
        border-color: #044c26;
        color: #044c26;  
		background-color: #FFFFFF; 
        -moz-border-radius: 10px;  
        -webkit-border-radius: 10px;  
        border-radius: 10px; /* future proofing */  
        -khtml-border-radius: 10px; /* for old Konqueror browsers */  
        text-align: center;  
        vertical-align: middle;  
        font-weight: 600;  
        font-size:100%  
      }  
.selfbutton:hover {
	background-color: #5cb85c; 
	color:  #FFFFFF;
}
.selfbutton-pos-30 {
	position: relative;
	top: 30px;
}
.selfbutton-pos-20 {
	position: relative;
	top: 10px;
}

.plan_box {
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.plan_box table {
  border-spacing: 1;
  width: 100%;
}
.plan_box table thead tr:nth-child(2n+1) {
  background-color: #E9F1F9;
}
.plan_box table tr {
  height: 35px;
  font-size: 14px;
  color: #666666;
  line-height: 14px;
  font-weight: 400;
}
.plan_box table tr:nth-child(2n) {
  background-color: #D0E1F2;
}
.plan_box table .head {
  font-size: 14px;
  color: #666666;
  background-color: #4EA6DC;
  line-height: 14px;
  font-weight: 600;
}

</style>
